import * as React from "react";
import { HeadFC } from "gatsby";
import Header_Layout from "../components/layouts/Header_Layout";
import { heading_styles } from "../styles/Add_Build_Styles";
import "../styles/main.css";
import G_Link from "../components/global/G_Link";

const page_styles = {
  color: "#232129",
  paddingTop: 16,
  paddingLeft: 96,
  paddingBottom: 96,
  paddingRight: 96,
  fontFamily: "Ubuntu, Roboto, sans-serif, serif",
  textAlign: "center" as const,
  fontSize: 24,
};

const link_styles = {
  textDecoration: "none",
  fontWeight: "1000",
};

const NotFoundPage = () => {
  return (
    <Header_Layout>
      <main style={page_styles}>
        <h1 style={heading_styles}>Page not found</h1>
        <p>
          Sorry 😔, we couldn’t find what you were looking for.
          <br />
          <span style={link_styles}>
            <G_Link href="/">Click to go home</G_Link>
          </span>{" "}
          or to{" "}
          <span style={link_styles}>
            <G_Link href="/garage">your Garage</G_Link>
          </span>
          .
        </p>
      </main>
    </Header_Layout>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
